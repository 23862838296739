<template>
  <div class="signUp">
    <div class="tc">
      <el-image
        :src="logo"
        class="image cursor"
        @click="$router.push('/')"
      ></el-image>
    </div>
    <div
      class="loginContent"
      :class="LOCALE == 'en' ? 'enTabs' : 'zhTabs'"
      v-if="!isSignUp"
    >
          <div class="moudle">
            <signUp
              @successChange="successChange"
              @getLoading="btnLoadingChange"
            >
              <el-button
                slot="footerBtn"
                type="primary"
                class="loginBut"
                size="small"
                :loading="btnLoading"
                >{{ $t("immedRegister") }}</el-button
              >
              <el-button
                slot="footerBtn1"
                type="primary"
                class="loginBut"
                size="small"
                >{{ $t("next") }}</el-button
              >
            </signUp>
          </div>
          <div class="clearfix">
        <div class="fr">
          {{ $t("hasAccount") }}，<span class="cursor" style="color: #91BE42" @click="toSign">
            {{ $t("emailAladyTip2") }}></span
          >
        </div>
      </div>
    </div>
    <div v-else class="SignUpBox">
      <div class="SignUpBoxContent">
        <i class="el-icon-success SignUpIcon"></i>
        <div style="margin-left: 10px; padding-top: 8px">
          <div class="tip_header">{{ $t("registerSuccess") }}</div>
          <div class="goLogin" @click="toSign">
            {{ $t("emailAladyTip2") }}
          </div>
        </div>
      </div>
    </div>
    <div class="privacy">
      <div class="">
        <router-link to="/privacypolicy" target="_blank">
          注册视为您已同意 <span class="green">用户协议与隐私条款</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import signUp from "~ymp/components/login/signUp";
export default {
  components: { signUp },
  data() {
    return {
      isSignUp: false,
      btnLoading: false,
      emailLoading: false,
      logo: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/logo.png",
    };
  },
  methods: {
    btnLoadingChange(val) {
        this.btnLoading = val;
    },
    successChange() {
      this.isSignUp = true;
    },
    toSign() {
      this.$router.push("/sign");
    },
  },
};
</script>
<style lang="less" scoped>
.signUp {
  width: 470px;
  padding: 0 40px;
  .image {
    width: 185px;
  }
  .subtitle {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    min-height: 30px;
    margin: 30px 0;
    line-height: 25px;
  }
  .loginContent {
     margin-top: 30px;
    .loginBut {
      width: 390px;
      display: block;
      height: 40px;
      margin: 24px auto 12px;
      border: 0 none;
      font-size: 16px;
      background: #91BE42;
    }
  }
  .SignUpBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 200px;
    .SignUpBoxContent {
      display: flex;
      .SignUpIcon {
        font-size: 60px;
        color: #91BE42;
      }
    }
    .tip_header {
      font-weight: 600;
      font-size: 14px;
    }
    .goLogin {
      margin-top: 8px;
      cursor: pointer;
      font-weight: 600;
      color: #91BE42;
    }
    .small {
      font-size: 14px;
    }
  }
  .moudle {
    margin-top: 10px;
  }
}
.privacy{
  padding: 20px 0;
  .green{
    color: #91BE42;
  }
}
</style>